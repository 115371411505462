import useToken from '@hooks/store/useToken';
import {
  apiRoute,
  BasicListApiResponse,
  requestSecureGet,
  requestSecurePost,
} from '@libs/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ProductItemTypes,
  StoreTypes,
} from 'typedef/components/Admin/admin.types';
import AdminProduct from '../components/AdminProduct';

const AdminProductContainer = () => {
  const navigate = useNavigate();

  const { accessToken } = useToken();

  const [productList, setProductList] = useState<ProductItemTypes[]>([]);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [popupShow, setPopupShow] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);

  const [storeOption, setStoreOption] = useState<StoreTypes[]>([]);
  const [selectedStore, setSelectedStore] = useState<StoreTypes | null>(null);

  const loadProductList = useCallback(async () => {
    const { config, data } = await requestSecureGet<
      BasicListApiResponse<ProductItemTypes[]>
    >(apiRoute.product.getProducts + `?page=${page}`, {}, accessToken!);

    if (config.status === 200) {
      setProductList(data.data);
      setMaxPage(data.meta.pageCount);
    } else {
      alert('목록을 불러오지 못했습니다');
    }
  }, [page, accessToken]);

  const onListItemClicked = useCallback(
    (item: ProductItemTypes) => {
      navigate('/admin/product/detail', {
        state: {
          item,
        },
      });
    },
    [navigate],
  );

  const onAddBtnClicked = useCallback(() => {
    setPopupShow(true);
  }, []);

  const onBackDropClicked = useCallback(() => {
    setPopupShow(false);
  }, []);

  const onAddDoneClicked = useCallback(async () => {
    if (!selectedStore) {
      alert('가게를 선택해주세요');
      return;
    }

    const { config } = await requestSecurePost<ProductItemTypes>(
      apiRoute.product.addProducts,
      {},
      {
        name,
        price,
        storeId: selectedStore.id,
      },
      accessToken!,
    );

    if (config.status === 201) {
      alert('추가되었습니다');
      setName('');
      setPrice(0);
      setPage(1);
      loadProductList();
      setPopupShow(false);
    } else {
      alert('추가에 실패 했습니다');
    }
  }, [accessToken, name, price, selectedStore, loadProductList]);

  const loadStoreOptionList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<StoreTypes[]>
    >(apiRoute.store.getStores, {}, accessToken!);

    if (config.status === 200) {
      setStoreOption(data.data);
    } else {
      alert('선택 목록을 불러오지 못했습니다');
    }
  }, [accessToken]);

  useEffect(() => {
    loadProductList();
  }, [loadProductList]);

  useEffect(() => {
    loadStoreOptionList();
  }, [loadStoreOptionList]);

  return (
    <AdminProduct
      productList={productList}
      setPage={setPage}
      maxPage={maxPage}
      onListItemClicked={onListItemClicked}
      popupShow={popupShow}
      onAddBtnClicked={onAddBtnClicked}
      onBackDropClicked={onBackDropClicked}
      onAddDoneClicked={onAddDoneClicked}
      setName={setName}
      setPrice={setPrice}
      setSelectedStore={setSelectedStore}
      storeOption={storeOption}
    />
  );
};

export default AdminProductContainer;
