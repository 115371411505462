import React from 'react';
import { CouponTypes } from 'typedef/components/Result/result.types';

type Props = {
  usedCoupon: CouponTypes;
};

const Result = ({ usedCoupon }: Props) => {
  return (
    <div className='conetent-root'>
      <h1>{`${usedCoupon.name}이 사용되었습니다`}</h1>
    </div>
  );
};

export default Result;
