import React, { useCallback, useEffect, useState } from 'react';
import CSelect from '../CSelect';

type Props<T> = {
  options: T[];
  labelSelector: (item: T) => string;
  onSelected: (item: T) => void;
  placeholder?: string;
};

function CSelectContainer<T>({
  options,
  labelSelector,
  onSelected,
  placeholder = '',
}: Props<T>) {
  const [optionVisible, setOptionVisible] = useState(false);

  const [selected, setSelected] = useState<T | null>(null);

  const onSelectInputClicked = useCallback(() => {
    setOptionVisible((prev) => !prev);
  }, []);

  const onItemSelected = useCallback((item: T) => {
    setOptionVisible(false);
    setSelected(item);
  }, []);

  useEffect(() => {
    if (selected) {
      onSelected(selected);
    }
  }, [selected]);

  return (
    <CSelect
      onSelectInputClicked={onSelectInputClicked}
      selected={selected}
      options={options}
      labelSelector={labelSelector}
      placeholder={placeholder}
      optionVisible={optionVisible}
      onItemSelected={onItemSelected}
    />
  );
}

export default CSelectContainer;
