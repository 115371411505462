export function formatNumber(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  return `${date.getFullYear()}년${
    date.getMonth() + 1
  }월${date.getDate()}일 ${date.getHours()}시${date.getMinutes()}분${date.getSeconds()}초`;
}
