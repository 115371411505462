export function getPageList(currentPage: number, maxPage: number): number[] {
  const start = 10 * Math.floor(currentPage / 10);
  const end = start + 10;

  const result = [];

  for (let i = start; i < end; i++) {
    if (i > 0 && i <= maxPage) {
      result.push(i);
    }
  }

  return result;
}
