import React from 'react';

const Home = () => {
  return (
    <div
      className='content-root'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h1>발급된 QR 코드를 인식해주세요</h1>
    </div>
  );
};

export default Home;
