import useToken from '@hooks/store/useToken';
import {
  apiRoute,
  BasicApiResponse,
  BasicListApiResponse,
  requestSecureGet,
  requestSecurePost,
} from '@libs/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CouponItemTypes,
  ProductItemTypes,
} from 'typedef/components/Admin/admin.types';
import AdminProductDetail from '../components/AdminProductDetail';

const AdminProductDetailContainer = () => {
  const { item } = useLocation().state as {
    item: ProductItemTypes;
  };

  const { accessToken } = useToken();

  const navigate = useNavigate();

  const [couponAddPopupVisible, setCouponAddPopupVisible] = useState(false);

  const [couponList, setCouponList] = useState<CouponItemTypes[]>([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [amount, setAmount] = useState(0);
  const [name, setName] = useState('');
  const [couponNumber, setCouponNumber] = useState(0);

  const onCouponAddBtnClicked = useCallback(() => {
    setCouponAddPopupVisible(true);
  }, []);

  const onListItemClicked = useCallback(() => {
    navigate('/admin/coupon/detail');
  }, [navigate]);

  const onBackDropClicked = useCallback(() => {
    setCouponAddPopupVisible(false);
  }, []);

  const loadList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<CouponItemTypes[]>
    >(
      apiRoute.coupon.getCoupons + `?page=${page}&productId=${item.id}`,
      {},
      accessToken!,
    );

    if (config.status === 200) {
      setCouponList(data.data);
      setMaxPage(data.meta.pageCount);
    } else {
      alert('목록을 불러오지 못했습니다');
    }
  }, [page, item, accessToken]);

  const onCouponAddDoneClicked = useCallback(async () => {
    for (let i = 0; i < couponNumber; i++) {
      const { config } = await requestSecurePost<CouponItemTypes>(
        apiRoute.coupon.addCoupons,
        {},
        {
          amount,
          name,
          productId: item.id,
        },
        accessToken!,
      );

      if (config.status !== 201) {
        alert('추가에 실패 했습니다');
        setAmount(0);
        setName('');
        setCouponNumber(0);
        setCouponAddPopupVisible(false);
        setPage(1);
        loadList();
        return;
      }
    }

    alert('추가에 성공했습니다');
    setAmount(0);
    setName('');
    setCouponNumber(0);
    setCouponAddPopupVisible(false);
    setPage(1);
    loadList();
  }, [loadList, amount, name, item, couponNumber, accessToken]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <AdminProductDetail
      item={item}
      couponList={couponList}
      onCouponAddBtnClicked={onCouponAddBtnClicked}
      couponAddPopupVisible={couponAddPopupVisible}
      onListItemClicked={onListItemClicked}
      onBackDropClicked={onBackDropClicked}
      setPage={setPage}
      maxPage={maxPage}
      onCouponAddDoneClicked={onCouponAddDoneClicked}
      setAmount={setAmount}
      setName={setName}
      setCouponNumber={setCouponNumber}
    />
  );
};

export default AdminProductDetailContainer;
