import React, {
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import colors from '../assets/colors';
import COutlineAdaptiveInput from '../components/COutlineAdapticeInput';
import COutlineInput from '../components/COutlineInput';
import CUnderlineAdaptiveInput from '../components/CUnderlineAdaptiveInput';
import CUnderlineInput from '../components/CUnderlineInput';
import '../styles/cinput.styles.css';

type Props = {
  containerType: {
    type: 'outline' | 'underline';
    adaptive: boolean;
    icon?: string;
  };
  type: HTMLInputTypeAttribute;
  inputId: string;
  containerStyle?: React.CSSProperties;
  onInputChanged: (item: string, isValid: boolean) => void;
  label: string;
  testValue?: (item: string) => boolean;
  error?: {
    onError: boolean;
    errorMessage: string;
  };
  disabled?: boolean;
  initialValue?: string;
  isRequired?: boolean;
  placeholder?: string;
};

const CInputContainer = ({
  containerType,
  type,
  inputId,
  containerStyle = {},
  onInputChanged,
  label,
  testValue = () => true,
  error = {
    onError: false,
    errorMessage: '',
  },
  disabled,
  initialValue,
  isRequired = false,
  placeholder = '입력해주세요',
}: Props) => {
  const [input, setInput] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onFocused = useCallback(() => {
    if (inputRef.current) {
      setIsFocused(true);

      if (containerType.type === 'outline') {
        inputRef.current.style.border = `2px solid ${colors.FOCUS_BLUE}`;
      } else {
        inputRef.current.style.borderBottom = `2px solid ${colors.FOCUS_BLUE}`;
      }
    }
  }, [inputRef, containerType]);

  const onBlured = useCallback(() => {
    if (inputRef.current) {
      setIsFocused(false);

      if (containerType.type === 'outline') {
        inputRef.current.style.border = error.onError
          ? `2px solid ${colors.ERROR}`
          : `1px solid ${colors.DEFAULT_BOX}`;
      } else {
        inputRef.current.style.borderBottom = error.onError
          ? `2px solid ${colors.ERROR}`
          : `1px solid ${colors.DEFAULT_BOX}`;
      }
    }
  }, [inputRef, containerType, error]);

  const onDeleteInputClicked = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setInput('');
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    onInputChanged(input, testValue(input));
  }, [onInputChanged, input, testValue]);

  useEffect(() => {
    if (inputRef.current && error.onError) {
      if (containerType.type === 'outline') {
        inputRef.current.style.border = `2px solid ${colors.ERROR}`;
      } else {
        inputRef.current.style.borderBottom = `2px solid ${colors.ERROR}`;
      }
    }
  }, [inputRef, containerType, error]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = initialValue ?? '';
    }

    setInput(initialValue ?? '');
  }, [inputRef, initialValue]);

  return (
    <div className='cinput-root' style={containerStyle}>
      {containerType.type === 'outline' ? (
        <>
          {!containerType.adaptive && (
            <COutlineInput
              placeholder={placeholder}
              inputId={inputId}
              input={input}
              setInput={setInput}
              type={type}
              label={label}
              error={error}
              inputRef={inputRef}
              icon={containerType.icon}
              onFocused={onFocused}
              onBlured={onBlured}
              onDeleteInputClicked={onDeleteInputClicked}
              disabled={disabled}
              isRequired={isRequired}
              isFocused={isFocused}
            />
          )}
          {containerType.adaptive && (
            <COutlineAdaptiveInput
              placeholder={placeholder}
              inputId={inputId}
              input={input}
              setInput={setInput}
              type={type}
              label={label}
              error={error}
              inputRef={inputRef}
              onFocused={onFocused}
              onBlured={onBlured}
              onDeleteInputClicked={onDeleteInputClicked}
              disabled={disabled}
              isRequired={isRequired}
              isFocused={isFocused}
            />
          )}
        </>
      ) : (
        <>
          {!containerType.adaptive && (
            <CUnderlineInput
              placeholder={placeholder}
              inputId={inputId}
              input={input}
              setInput={setInput}
              type={type}
              label={label}
              error={error}
              inputRef={inputRef}
              onFocused={onFocused}
              onBlured={onBlured}
              onDeleteInputClicked={onDeleteInputClicked}
              disabled={disabled}
              isRequired={isRequired}
              isFocused={isFocused}
            />
          )}
          {containerType.adaptive && (
            <CUnderlineAdaptiveInput
              placeholder={placeholder}
              inputId={inputId}
              input={input}
              setInput={setInput}
              type={type}
              label={label}
              error={error}
              inputRef={inputRef}
              onFocused={onFocused}
              onBlured={onBlured}
              onDeleteInputClicked={onDeleteInputClicked}
              disabled={disabled}
              isRequired={isRequired}
              isFocused={isFocused}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CInputContainer;
