import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CModal from '@components/common/CModal/CModal';
import CPageNationContainer from '@components/common/CPageNation/containers/CPageNationContainer';
import { WEB_ROOT } from '@libs/config';
import { formatDate } from '@libs/factory';
import React from 'react';
import {
  CouponItemTypes,
  ProductItemTypes,
} from 'typedef/components/Admin/admin.types';
import '../styles/admin.product.detail.styles.css';

type Props = {
  item: ProductItemTypes;
  couponList: CouponItemTypes[];
  onCouponAddBtnClicked: () => void;
  couponAddPopupVisible: boolean;
  onListItemClicked: () => void;
  onBackDropClicked: () => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
  onCouponAddDoneClicked: () => void;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setCouponNumber: React.Dispatch<React.SetStateAction<number>>;
};

const AdminProductDetail = ({
  item,
  couponList,
  onCouponAddBtnClicked,
  couponAddPopupVisible,
  onBackDropClicked,
  setPage,
  maxPage,
  onCouponAddDoneClicked,
  setAmount,
  setName,
  setCouponNumber,
}: Props) => {
  return (
    <div className='content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{item.name}</div>
          <div className='subtitle-label'>{item.price + ' 원'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onCouponAddBtnClicked}>
          추가하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>사용여부</div>
          <div className='item'>이름</div>
          <div className='item'>할인 가격</div>
          <div className='item'>링크</div>
        </div>
        <div className='body-section'>
          {couponList.map((item, idx) => (
            <div
              className='row'
              key={idx}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${WEB_ROOT}/coupon?item_uid=${item.id}&name=${item.name}`,
                );
              }}>
              <div className='item'>
                {item.usedAt ? formatDate(item.usedAt) : '미사용'}
              </div>
              <div className='item'>{item.name}</div>
              <div className='item'>{item.amount}</div>
              <div className='item'>{`${WEB_ROOT}/coupon?item_uid=${item.id}&name=${item.name}`}</div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPageNationContainer onPageChanged={setPage} maxPage={maxPage} />
        </div>
      </div>
      <CModal
        visible={couponAddPopupVisible}
        onBackdropClicked={onBackDropClicked}>
        <div
          className='coupon-add-container'
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className='table-container'>
            <div className='row'>
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={(val) => setAmount(parseInt(val))}
                type='number'
                inputId='coupon-amount-input'
                label='할인가격'
              />
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={setName}
                type='text'
                inputId='coupon-name-input'
                label='쿠폰 이름'
              />
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={(val) => setCouponNumber(parseInt(val))}
                type='number'
                inputId='coupon-number-input'
                label='쿠폰 발행량'
              />
              <button className='primary-btn' onClick={onCouponAddDoneClicked}>
                완료
              </button>
            </div>
          </div>
        </div>
      </CModal>
    </div>
  );
};

export default AdminProductDetail;
