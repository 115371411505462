import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CModal from '@components/common/CModal/CModal';
import CPageNationContainer from '@components/common/CPageNation/containers/CPageNationContainer';
import { formatDate } from '@libs/factory';
import React from 'react';
import {
  PartnersTypes,
  StoreTypes,
} from 'typedef/components/Admin/admin.types';
import '../styles/admin.account.styles.css';

type Props = {
  partnersList: PartnersTypes[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
  popupShow: boolean;
  onAddBtnClicked: () => void;
  onBackDropClicked: () => void;
  onAddDoneClicked: () => void;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
};

const AdminAccount = ({
  partnersList,
  setPage,
  maxPage,
  popupShow,
  onAddBtnClicked,
  onBackDropClicked,
  onAddDoneClicked,
  setId,
  setPassword,
  setName,
}: Props) => {
  return (
    <div className='content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'계정 관리'}</div>
          <div className='subtitle-label'>{'계정 관리 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          추가하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>이름</div>
          <div className='item'>아이디</div>
          <div className='item'>가입일</div>
        </div>
        <div className='body-section'>
          {partnersList.map((item, idx) => (
            <div className='row' key={idx}>
              <div className='item'>{item.displayName}</div>
              <div className='item'>{item.displayName}</div>
              <div className='item'>{formatDate(item.createdAt)}</div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPageNationContainer onPageChanged={setPage} maxPage={maxPage} />
        </div>
      </div>
      <CModal visible={popupShow} onBackdropClicked={onBackDropClicked}>
        <div
          className='user-add-container'
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className='table-container'>
            <div className='row'>
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={setId}
                type='text'
                inputId='user-id-input'
                label='아이디'
              />
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={setPassword}
                type='password'
                inputId='user-password-input'
                label='비밀번호'
              />
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={setName}
                type='text'
                inputId='user-name-input'
                label='닉네임'
              />
              <button className='primary-btn' onClick={onAddDoneClicked}>
                완료
              </button>
            </div>
          </div>
        </div>
      </CModal>
    </div>
  );
};

export default AdminAccount;
