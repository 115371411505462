import React from 'react';
import {
  AdminGnbTabTypes,
  AdminTabTypes,
} from 'typedef/components/Admin/admin.types';
import '../styles/admin.gnb.styles.css';

type Props = {
  selectedTab: AdminTabTypes | null;
  data: AdminGnbTabTypes[];
  onTabClicked: (path: AdminTabTypes) => void;
  onLogoutClicked: () => void;
};

const AdminGnb = ({
  selectedTab,
  data,
  onTabClicked,
  onLogoutClicked,
}: Props) => {
  return (
    <div className='gnb-root'>
      {data.map((item, idx) => (
        <div
          key={idx}
          className={`gnb-item-container ${
            selectedTab === item.path && 'selected'
          }`}
          onClick={() => onTabClicked(item.path)}>
          <span className='gnb-item-label'>{item.label}</span>
        </div>
      ))}
      <button
        style={{
          marginTop: '20px',
        }}
        className='primary-btn'
        onClick={onLogoutClicked}>
        로그아웃
      </button>
    </div>
  );
};

export default AdminGnb;
