import React from 'react';
import images from '../assets/images';
import { CInputPropTypes } from '../typedef/cinput.types';
import '../styles/cunderline.input.styles.css';

const CUnderlineInput = ({
  inputId,
  input,
  setInput,
  type,
  label,
  error: { onError, errorMessage },
  inputRef,
  onFocused,
  onBlured,
  onDeleteInputClicked = () => {},
  disabled = false,
  isRequired,
  placeholder,
}: CInputPropTypes) => {
  return (
    <div className='cunderline-root'>
      <label htmlFor={inputId}>
        <span className='cunderline-label'>
          {label}
          {isRequired && <span className='cunderline-input-required'>*</span>}
        </span>
      </label>
      <div className='cunderline-input'>
        {disabled ? (
          <div className='cunderline-disabled-input'>{input}</div>
        ) : (
          <>
            <input
              id={inputId}
              onFocus={onFocused}
              onBlur={onBlured}
              className='cunderline-input-input'
              ref={inputRef}
              type={type}
              onChange={(e) => setInput(e.target.value)}
              placeholder={placeholder}
            />
            {input !== '' && (
              <button
                className='cunderline-delete-btn'
                onClick={onDeleteInputClicked}>
                <img
                  src={images.deleteBtn}
                  alt={`delete input btn of ${inputId}`}
                />
              </button>
            )}
          </>
        )}
      </div>
      {!disabled && onError && (
        <div className='cunderline-error-message'>{errorMessage}</div>
      )}
    </div>
  );
};

export default CUnderlineInput;
