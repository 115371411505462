import { RootState } from '@store/rootReducer';
import {
  updateAccessTokenAction,
  updateRefreshTokenAction,
} from '@store/token/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useToken() {
  const { accessToken, refreshToken } = useSelector(
    (root: RootState) => root.tokenReducer,
  );

  const dispatch = useDispatch();

  const __updateAccessTokenFromHooks = useCallback(
    (diff: string) => dispatch(updateAccessTokenAction(diff)),
    [dispatch],
  );

  const __updateRefreshTokenFromHooks = useCallback(
    (diff: string) => dispatch(updateRefreshTokenAction(diff)),
    [dispatch],
  );

  return {
    accessToken,
    refreshToken,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
  };
}
