import useToken from '@hooks/store/useToken';
import useUid from '@hooks/useUid';
import { apiRoute, requestPost } from '@libs/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';

const LoginContainer = () => {
  const navigate = useNavigate();

  const {
    __getUidFromHooks,
    __setUidFromHooks,
    __getPasswordFromHooks,
    __setPasswordFromHooks,
  } = useUid();

  const { __updateAccessTokenFromHooks, __updateRefreshTokenFromHooks } =
    useToken();

  const [uid, setUid] = useState<null | string>(null);
  const [password, setPassword] = useState<null | string>(null);

  const checkUid = useCallback(async () => {
    const uid = __getUidFromHooks();
    const password = __getPasswordFromHooks();

    if (!uid || !password) {
      return;
    } else {
      const { config, data } = await requestPost<{
        accessToken: string;
        refreshToken: string;
      }>(
        apiRoute.auth.login,
        {},
        {
          userName: uid,
          password,
        },
      );

      if (config.status === 200) {
        __updateAccessTokenFromHooks(data.accessToken);
        __updateRefreshTokenFromHooks(data.refreshToken);

        __setUidFromHooks(uid);
        __setPasswordFromHooks(password);
        navigate('/home');
      }
    }
  }, [
    __getUidFromHooks,
    __getPasswordFromHooks,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    __setUidFromHooks,
    __setPasswordFromHooks,
    navigate,
  ]);

  const onLoginBtnClicked = useCallback(async () => {
    if (!uid) {
      alert('ID를 입력해주세요');
      return;
    }

    if (!password) {
      alert('Password를 입력해주세요');
      return;
    }

    const { config, data } = await requestPost<{
      accessToken: string;
      refreshToken: string;
    }>(
      apiRoute.auth.login,
      {},
      {
        userName: uid,
        password,
      },
    );

    if (config.status === 200) {
      __updateAccessTokenFromHooks(data.accessToken);
      __updateRefreshTokenFromHooks(data.refreshToken);

      __setUidFromHooks(uid);
      __setPasswordFromHooks(password);
      navigate('/home');
    }

    __setUidFromHooks(uid);
  }, [
    uid,
    password,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    __setUidFromHooks,
    __setPasswordFromHooks,
    navigate,
  ]);

  useEffect(() => {
    checkUid();
  }, [checkUid]);

  return (
    <Login
      onLoginBtnClicked={onLoginBtnClicked}
      setUid={setUid}
      setPassword={setPassword}
    />
  );
};

export default LoginContainer;
