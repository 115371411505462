import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';

type Props = {
  onLoginBtnClicked: () => void;
  setUid: React.Dispatch<React.SetStateAction<string | null>>;
  setPassword: React.Dispatch<React.SetStateAction<string | null>>;
};

const Login = ({ onLoginBtnClicked, setUid, setPassword }: Props) => {
  return (
    <div
      className='content-root'
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CInputContainer
        containerType={{
          type: 'outline',
          adaptive: false,
        }}
        inputId='login-id-input'
        type='text'
        label='아이디'
        onInputChanged={setUid}
      />
      <CInputContainer
        containerType={{
          type: 'outline',
          adaptive: false,
        }}
        inputId='login-pw-input'
        type='password'
        label='비밀번호'
        onInputChanged={setPassword}
      />
      <button className='primary-btn' onClick={onLoginBtnClicked}>
        로그인
      </button>
    </div>
  );
};

export default Login;
