import useToken from '@hooks/store/useToken';
import useUid from '@hooks/useUid';
import { apiRoute, requestPost } from '@libs/api';
import React, { useCallback, useEffect, useState } from 'react';
import Admin from '../Admin';

const AdminContainer = () => {
  const {
    __setUidFromHooks,
    __getUidFromHooks,
    __setPasswordFromHooks,
    __getPasswordFromHooks,
  } = useUid();

  const { __updateAccessTokenFromHooks, __updateRefreshTokenFromHooks } =
    useToken();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const [loginSuccess, setLoginSuccess] = useState(false);

  const checkUid = useCallback(async () => {
    const uid = __getUidFromHooks();
    const password = __getPasswordFromHooks();

    if (!uid || !password) {
      return;
    } else {
      const { config, data } = await requestPost<{
        accessToken: string;
        refreshToken: string;
      }>(
        apiRoute.auth.login,
        {},
        {
          userName: uid,
          password,
        },
      );

      if (config.status === 200) {
        __updateAccessTokenFromHooks(data.accessToken);
        __updateRefreshTokenFromHooks(data.refreshToken);

        __setUidFromHooks(uid);
        __setPasswordFromHooks(password);
        setLoginSuccess(true);
      } else {
        setLoginSuccess(false);
      }
    }
  }, [
    __getUidFromHooks,
    __getPasswordFromHooks,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    __setUidFromHooks,
    __setPasswordFromHooks,
  ]);

  const onLoginBtnClicked = useCallback(async () => {
    const { data, config } = await requestPost<{
      accessToken: string;
      refreshToken: string;
    }>(
      apiRoute.auth.login,
      {},
      {
        userName: id,
        password,
      },
    );

    if (config.status === 200) {
      alert('환영합니다');

      __updateAccessTokenFromHooks(data.accessToken);
      __updateRefreshTokenFromHooks(data.refreshToken);

      __setUidFromHooks(id);
      __setPasswordFromHooks(password);

      setLoginSuccess(true);
    } else {
      alert('로그인에 실패 했습니다');
      setLoginSuccess(false);
    }
  }, [
    id,
    password,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    __setUidFromHooks,
    __setPasswordFromHooks,
  ]);

  useEffect(() => {
    checkUid();
  }, [checkUid]);

  useEffect(() => {
    console.log(loginSuccess);
  }, [loginSuccess]);

  return (
    <Admin
      setId={setId}
      setPassword={setPassword}
      onLoginBtnClicked={onLoginBtnClicked}
      loginSuccess={loginSuccess}
    />
  );
};

export default AdminContainer;
