import React from 'react';
import { Routes, Route, Navigate } from 'react-router';
import LoginContainer from '@components/Login/containers/LoginContainer';
import ResultContainer from '@components/Result/containers/ResultContainer';
import HomeContainer from '@components/Home/containers/HomeContainer';
import AdminNavigationContainer from './containers/AdminNavigationContainer';
import CouponContainer from '@components/Coupon/containers/CouponContainer';

const RootNavigation = () => {
  return (
    <Routes>
      <Route path='/login' element={<LoginContainer />} />
      <Route path='/coupon' element={<CouponContainer />} />
      <Route path='/result' element={<ResultContainer />} />
      <Route path='/home' element={<HomeContainer />} />
      <Route path='/admin/*' element={<AdminNavigationContainer />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );
};

export default RootNavigation;
