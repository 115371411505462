import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

import './styles/coupon.styles.css';

type Props = {
  itemName: string;
  qrLink: string;
};

const Coupon = ({ itemName, qrLink }: Props) => {
  return (
    <div className='content-root coupon-root'>
      <div className='qr-container'>
        <QRCodeSVG value={qrLink} size={168} />
        <div className='name-label'>{itemName}</div>
      </div>
    </div>
  );
};

export default Coupon;
