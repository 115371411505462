import React from 'react';
import images from './assets/images';
import './styles/cselect.styles.css';

type Props<T> = {
  onSelectInputClicked: () => void;
  selected: T | null;
  options: T[];
  labelSelector: (item: T) => string;
  placeholder: string;
  optionVisible: boolean;
  onItemSelected: (item: T) => void;
};

function CSelect<T>({
  onSelectInputClicked,
  selected,
  options,
  labelSelector,
  placeholder,
  optionVisible,
  onItemSelected,
}: Props<T>) {
  return (
    <div className={`cselect-root`}>
      <div
        className={`cselect-input ${optionVisible ? 'focus' : 'unfocus'}`}
        onClick={onSelectInputClicked}>
        <span
          className={`cselect-label ${selected ? 'selected' : 'unselected'}`}>
          {selected ? labelSelector(selected) : placeholder}
        </span>
        <div className='cselect-under-arrow'>
          <img src={images.underArrow} alt='cselect under arrow' />
        </div>
      </div>
      <div className={`cselect-option ${optionVisible ? 'show' : 'hide'}`}>
        {options.map((item, index) => (
          <div
            key={index}
            className='cselect-option-item'
            onClick={() => onItemSelected(item)}>
            {labelSelector(item)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CSelect;
