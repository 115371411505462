import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';

type Props = {
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  onLoginBtnClicked: () => void;
  loginSuccess: boolean;
};

const Admin = ({
  setId,
  setPassword,
  onLoginBtnClicked,
  loginSuccess,
}: Props) => {
  return loginSuccess ? (
    <div className='content-root'>
      <h1>환영합니다!</h1>
    </div>
  ) : (
    <div className='content-root'>
      <h1>크누마켓 POC 어드민 페이지 입니다</h1>
      <CInputContainer
        containerType={{
          type: 'outline',
          adaptive: false,
        }}
        inputId='login-id-input'
        type='text'
        label='아이디'
        onInputChanged={setId}
      />
      <CInputContainer
        containerType={{
          type: 'outline',
          adaptive: false,
        }}
        inputId='login-pw-input'
        type='password'
        label='비밀번호'
        onInputChanged={setPassword}
      />
      <button className='primary-btn' onClick={onLoginBtnClicked}>
        로그인
      </button>
    </div>
  );
};

export default Admin;
