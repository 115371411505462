import {
  UPDATE_ACCESSTOKEN_ACITON,
  UPDATE_REFESHTOKEN_ACTION,
} from './modules/actionTypes';

export const updateAccessTokenAction = (accessToken: string) => ({
  type: UPDATE_ACCESSTOKEN_ACITON,
  payload: accessToken,
});

export const updateRefreshTokenAction = (refreshToken: string) => ({
  type: UPDATE_REFESHTOKEN_ACTION,
  payload: refreshToken,
});

export type TokenActionTypes =
  | ReturnType<typeof updateAccessTokenAction>
  | ReturnType<typeof updateRefreshTokenAction>;
