import React from 'react';
import './styles/cmodal.styles.css';

type Props = {
  children: React.ReactNode;
  visible: boolean;
  onBackdropClicked?: () => void;
};

const CModal = ({ children, visible, onBackdropClicked }: Props) => {
  return visible ? (
    <div className='cmodal-root' onClick={onBackdropClicked}>
      {children}
    </div>
  ) : null;
};

export default CModal;
