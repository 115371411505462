import Login from '@components/Login/Login';
import useToken from '@hooks/store/useToken';
import useUid from '@hooks/useUid';
import { apiRoute, requestPost, requestSecurePost } from '@libs/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CouponTypes } from 'typedef/components/Result/result.types';
import Result from '../Result';

const ITEM_SEARCH_PARAM_KEY = 'item_uid';

const ResultContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [usedCoupon, setUsedCoupon] = useState<CouponTypes | null>(null);

  const [uid, setUid] = useState<string | null>(null);
  const [password, setPassword] = useState<null | string>(null);

  const [isLoginDone, setIsLoginDone] = useState(false);

  const {
    __getUidFromHooks,
    __setUidFromHooks,
    __getPasswordFromHooks,
    __setPasswordFromHooks,
  } = useUid();

  const {
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    accessToken,
  } = useToken();

  const itemUid = useMemo(
    () => searchParams.get(ITEM_SEARCH_PARAM_KEY),
    [searchParams],
  );

  const checkUid = useCallback(async () => {
    const uid = __getUidFromHooks();
    const password = __getPasswordFromHooks();

    if (!uid || !password) {
      setIsLoginDone(false);
      return;
    } else {
      const { config, data } = await requestPost<{
        accessToken: string;
        refreshToken: string;
      }>(
        apiRoute.auth.login,
        {},
        {
          userName: uid,
          password,
        },
      );

      if (config.status === 200) {
        __updateAccessTokenFromHooks(data.accessToken);
        __updateRefreshTokenFromHooks(data.refreshToken);

        __setUidFromHooks(uid);
        __setPasswordFromHooks(password);
        setIsLoginDone(true);
      } else {
        setIsLoginDone(false);
      }
    }
  }, [
    __getUidFromHooks,
    __getPasswordFromHooks,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    __setUidFromHooks,
    __setPasswordFromHooks,
  ]);

  const onLoginBtnClicked = useCallback(async () => {
    if (!uid) {
      alert('ID를 입력해주세요');
      return;
    }

    if (!password) {
      alert('Password를 입력해주세요');
      return;
    }

    const { config, data } = await requestPost<{
      accessToken: string;
      refreshToken: string;
    }>(
      apiRoute.auth.login,
      {},
      {
        userName: uid,
        password,
      },
    );

    if (config.status === 200) {
      __updateAccessTokenFromHooks(data.accessToken);
      __updateRefreshTokenFromHooks(data.refreshToken);

      __setUidFromHooks(uid);
      __setPasswordFromHooks(password);

      setIsLoginDone(true);
    } else {
      alert('로그인에 실패 했습니다');
    }
  }, [
    uid,
    password,
    __updateAccessTokenFromHooks,
    __updateRefreshTokenFromHooks,
    __setUidFromHooks,
    __setPasswordFromHooks,
  ]);

  const itemUse = useCallback(async () => {
    if (!accessToken) {
      alert('로그인이 필요한 기능 입니다');
    } else {
      const { config, data } = await requestSecurePost<CouponTypes>(
        apiRoute.coupon.useCoupon + itemUid,
        {},
        {},
        accessToken,
      );

      if (config.status === 202) {
        alert('쿠폰이 사용되었습니다');
        setUsedCoupon(data);
      }
    }
  }, [accessToken, itemUid]);

  useEffect(() => {
    checkUid();
  }, [checkUid]);

  useEffect(() => {
    console.log(isLoginDone);
  }, [isLoginDone]);

  useEffect(() => {
    if (isLoginDone && accessToken) {
      itemUse();
    }
  }, [isLoginDone, accessToken, itemUse]);

  return isLoginDone ? (
    usedCoupon ? (
      <Result usedCoupon={usedCoupon} />
    ) : (
      <div>
        <h1>잠시만 기다려 주세요</h1>
      </div>
    )
  ) : (
    <Login
      setUid={setUid}
      onLoginBtnClicked={onLoginBtnClicked}
      setPassword={setPassword}
    />
  );
};

export default ResultContainer;
