import { apiRoute, requestGet } from '@libs/api';
import { GOOGLE_FORM_LINK, WEB_ROOT } from '@libs/config';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CouponItemTypes } from 'typedef/components/Admin/admin.types';
import Coupon from '../Coupon';

const ITEM_SEARCH_PARAM_KEY = 'item_uid';

const CouponContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [qrCheck, setQrCheck] = useState(false);
  const [isUsed, setIsUsed] = useState(false);

  const itemUid = useMemo(
    () => searchParams.get(ITEM_SEARCH_PARAM_KEY),
    [searchParams],
  );

  const itemName = useMemo(() => searchParams.get('name'), [searchParams]);

  const qrLink = useMemo(
    () => `${WEB_ROOT}/result?item_uid=${itemUid}`,
    [itemUid],
  );

  const checkUsed = useCallback(async () => {
    const { data, config } = await requestGet<CouponItemTypes>(
      apiRoute.coupon.getCouponDetail + itemUid,
      {},
    );

    if (config.status === 200) {
      setIsUsed(data.usedAt !== null);
      setQrCheck(true);
    } else {
      alert('상세 정보를 불러오지 못했습니다');
      setQrCheck(false);
    }
  }, []);

  useEffect(() => {
    checkUsed();
  }, [checkUsed]);

  useEffect(() => {
    console.log(qrCheck);
  }, [qrCheck]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const { data, config } = await requestGet<CouponItemTypes>(
        apiRoute.coupon.getCouponDetail + itemUid,
        {},
      );

      if (config.status === 200 && data.usedAt !== null) {
        window.location.href = GOOGLE_FORM_LINK;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return qrCheck ? (
    isUsed ? (
      <div
        className='content-root'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h1>이미 사용된 쿠폰 입니다</h1>
      </div>
    ) : (
      <Coupon itemName={itemName || ''} qrLink={qrLink} />
    )
  ) : (
    <div></div>
  );
};

export default CouponContainer;
