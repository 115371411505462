import useToken from '@hooks/store/useToken';
import useUid from '@hooks/useUid';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AdminGnbTabTypes,
  AdminTabTypes,
} from 'typedef/components/Admin/admin.types';
import AdminGnb from '../components/AdminGnb';

const gnbItemList: AdminGnbTabTypes[] = [
  { label: '상품 관리', path: 'product' },
  { label: '계정 관리', path: 'account' },
];

const AdminGnbContainer = () => {
  const { accessToken } = useToken();
  const { __clearUidFromHooks } = useUid();

  const [selectedTab, setSelectedTab] = useState<AdminTabTypes | null>(null);

  const navigate = useNavigate();

  const onTabClicked = useCallback(
    (path: AdminTabTypes) => {
      navigate(path);
      setSelectedTab(path);
    },
    [navigate],
  );

  const onLogoutClicked = useCallback(() => {
    __clearUidFromHooks();
    alert('로그아웃 되었습니다');
    navigate('/admin');
    window.location.reload();
  }, [__clearUidFromHooks, navigate]);

  return (
    <AdminGnb
      selectedTab={selectedTab}
      data={accessToken ? gnbItemList : []}
      onTabClicked={onTabClicked}
      onLogoutClicked={onLogoutClicked}
    />
  );
};

export default AdminGnbContainer;
