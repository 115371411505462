import useToken from '@hooks/store/useToken';
import {
  apiRoute,
  BasicListApiResponse,
  requestPost,
  requestSecureGet,
  requestSecurePost,
} from '@libs/api';
import React, { useCallback, useEffect, useState } from 'react';
import {
  PartnersTypes,
  StoreTypes,
} from 'typedef/components/Admin/admin.types';
import AdminAccount from '../components/AdminAccount';

const AdminAccountContainer = () => {
  const { accessToken } = useToken();

  const [partnersList, setPartnersList] = useState<PartnersTypes[]>([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [popupShow, setPopupShow] = useState(false);

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const loadList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListApiResponse<PartnersTypes[]>
    >(
      apiRoute.user.getPartners + `?page=${page}&qColumnNames=role&q=PARTNERS`,
      {},
      accessToken!,
    );

    if (config.status === 200) {
      setPartnersList(data.data);
      setMaxPage(data.meta.pageCount);
    } else {
      alert('목록을 불러오지 못했습니다');
    }
  }, [page, accessToken]);

  const onAddBtnClicked = useCallback(() => {
    setPopupShow(true);
  }, []);

  const onBackDropClicked = useCallback(() => {
    setPopupShow(false);
  }, []);

  const onAddDoneClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<PartnersTypes>(
      apiRoute.user.addPartners,
      {},
      {
        password,
        userName: id,
        displayName: name,
        role: 'PARTNERS',
      },
      accessToken!,
    );

    if (config.status === 201) {
      const { data, config } = await requestPost<{
        accessToken: string;
        refreshToken: string;
      }>(
        apiRoute.auth.login,
        {},
        {
          userName: id,
          password,
        },
      );

      if (config.status === 200) {
        const { config } = await requestSecurePost<StoreTypes>(
          apiRoute.store.addStore,
          {},
          { name: name + '_의_가게' },
          data.accessToken,
        );

        if (config.status === 201) {
          alert('추가 되었습니다');
          setId('');
          setName('');
          setPassword('');
          setPopupShow(false);
          setPage(1);
          loadList();
        } else {
          alert('가게 등록에 실패했습니다');
        }
      }
    } else {
      alert('추가에 실패했습니다');
    }
  }, [id, password, name, accessToken, loadList]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <AdminAccount
      partnersList={partnersList}
      setPage={setPage}
      maxPage={maxPage}
      popupShow={popupShow}
      onAddBtnClicked={onAddBtnClicked}
      onBackDropClicked={onBackDropClicked}
      onAddDoneClicked={onAddDoneClicked}
      setId={setId}
      setPassword={setPassword}
      setName={setName}
    />
  );
};

export default AdminAccountContainer;
