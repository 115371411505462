import AdminAccountContainer from '@components/Admin/containers/AdminAccountContainer';
import AdminContainer from '@components/Admin/containers/AdminContainer';
import AdminCouponContainer from '@components/Admin/containers/AdminCouponContainer';
import AdminGnbContainer from '@components/Admin/containers/AdminGnbContainer';
import AdminProductContainer from '@components/Admin/containers/AdminProductContainer';
import AdminProductDetailContainer from '@components/Admin/containers/AdminProductDetailContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AdminNavigation = () => {
  return (
    <div className='root'>
      <AdminGnbContainer />
      <Routes>
        <Route path='/' element={<AdminContainer />} />
        <Route path='account' element={<AdminAccountContainer />} />
        <Route path='product' element={<AdminProductContainer />} />
        <Route path='coupon' element={<AdminCouponContainer />} />
        <Route
          path='product/detail'
          element={<AdminProductDetailContainer />}
        />
      </Routes>
    </div>
  );
};

export default AdminNavigation;
