import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CPageNation from '../CPageNation';
import { getPageList } from '../libs/getPageList';

type Props = {
  maxPage: number;
  onPageChanged: (page: number) => void;
};

const CPageNationContainer = ({ maxPage, onPageChanged }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageList = useMemo(() => {
    return getPageList(currentPage, maxPage);
  }, [currentPage, maxPage]);

  const onPageClicked = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onBackBtnClicked = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const onNextBtnClicked = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, maxPage));
  }, [maxPage]);

  useEffect(() => {
    onPageChanged(currentPage);
  }, [onPageChanged, currentPage]);

  return (
    <CPageNation
      currentPage={currentPage}
      pageList={pageList}
      onPageClicked={onPageClicked}
      onBackBtnClicked={onBackBtnClicked}
      onNextBtnClicked={onNextBtnClicked}
    />
  );
};

export default CPageNationContainer;
