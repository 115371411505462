import React, { RefObject } from 'react';
import { CInputPropTypes } from '../typedef/cinput.types';
import '../styles/coutline.adaptive.input.styles.css';
import images from '../assets/images';

const COutlineAdaptiveInput = ({
  inputId,
  input,
  setInput,
  type,
  label,
  error: { onError, errorMessage },
  inputRef,
  onFocused,
  onBlured,
  onDeleteInputClicked = () => {},
  disabled = false,
  isRequired,
  placeholder,
  isFocused,
}: CInputPropTypes) => {
  return (
    <div className='coutline-adaptive-root'>
      <label htmlFor={inputId} hidden className='coutline-adaptive-label'>
        <span className='coutline-adaptive-label'>
          {label}
          {isRequired && (
            <span className='coutline-adaptive-input-required'>*</span>
          )}
        </span>
      </label>
      <div className='coutline-adaptive-input'>
        {disabled ? (
          <div className='coutline-adaptive-disabled-input'>{input}</div>
        ) : (
          <>
            <input
              id={inputId}
              onFocus={onFocused}
              onBlur={onBlured}
              className='coutline-adaptive-input-input'
              ref={inputRef as RefObject<HTMLInputElement>}
              type={type}
              onChange={(e) => setInput(e.target.value)}
              placeholder={''}
            />
            {input !== '' && (
              <button
                className='coutline-adaptive-delete-btn'
                onClick={onDeleteInputClicked}>
                <img
                  src={images.deleteBtn}
                  alt={`delete input btn of ${inputId}`}
                />
              </button>
            )}
            <div className='coutline-adaptive-label'>
              <span
                className={`coutline-adaptive-label ${
                  input !== '' || isFocused ? 'active' : 'deactive'
                }`}>
                {label}
                {isRequired && (
                  <span className='coutline-adaptive-input-required'>*</span>
                )}
              </span>
            </div>
          </>
        )}
      </div>
      {!disabled && onError && (
        <div className='coutline-adaptive-error-message'>{errorMessage}</div>
      )}
    </div>
  );
};

export default COutlineAdaptiveInput;
