import { useCallback } from 'react';

const UID_KEY = '@uid';
const PASSWORD_KEY = '@password';

export default function useUid() {
  const __setUidFromHooks = useCallback((uid: string) => {
    try {
      localStorage.setItem(UID_KEY, uid);
    } catch {
      window.localStorage.setItem(UID_KEY, uid);
    }
  }, []);

  const __getUidFromHooks = useCallback(() => {
    try {
      return localStorage.getItem(UID_KEY);
    } catch {
      return window.localStorage.getItem(UID_KEY);
    }
  }, []);

  const __setPasswordFromHooks = useCallback((password: string) => {
    try {
      localStorage.setItem(PASSWORD_KEY, password);
    } catch {
      window.localStorage.setItem(PASSWORD_KEY, password);
    }
  }, []);

  const __getPasswordFromHooks = useCallback(() => {
    try {
      return localStorage.getItem(PASSWORD_KEY);
    } catch {
      return window.localStorage.getItem(PASSWORD_KEY);
    }
  }, []);

  const __clearUidFromHooks = useCallback(() => {
    try {
      localStorage.clear();
    } catch {
      window.localStorage.clear();
    }
  }, []);

  return {
    __setUidFromHooks,
    __getUidFromHooks,
    __setPasswordFromHooks,
    __getPasswordFromHooks,
    __clearUidFromHooks,
  };
}
