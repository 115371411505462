import { TokenActionTypes } from './actions';
import {
  UPDATE_ACCESSTOKEN_ACITON,
  UPDATE_REFESHTOKEN_ACTION,
} from './modules/actionTypes';

export type TokenState = {
  accessToken: null | string;
  refreshToken: null | string;
};

const init: TokenState = {
  accessToken: null,
  refreshToken: null,
};

const tokenReducer = (
  prev: TokenState = init,
  { type, payload }: TokenActionTypes,
): TokenState => {
  switch (type) {
    case UPDATE_ACCESSTOKEN_ACITON:
      return {
        ...prev,
        accessToken: payload,
      };

    case UPDATE_REFESHTOKEN_ACTION:
      return {
        ...prev,
        refreshToken: payload,
      };

    default:
      return prev;
  }
};

export default tokenReducer;
