import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CModal from '@components/common/CModal/CModal';
import CPageNationContainer from '@components/common/CPageNation/containers/CPageNationContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { formatNumber } from '@libs/factory';
import React from 'react';
import {
  ProductItemTypes,
  StoreTypes,
} from 'typedef/components/Admin/admin.types';
import '../styles/admin.product.styles.css';

type Props = {
  productList: ProductItemTypes[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
  onListItemClicked: (item: ProductItemTypes) => void;
  popupShow: boolean;
  onAddBtnClicked: () => void;
  onBackDropClicked: () => void;
  onAddDoneClicked: () => void;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStore: React.Dispatch<React.SetStateAction<StoreTypes | null>>;
  storeOption: StoreTypes[];
};

const AdminProduct = ({
  productList,
  setPage,
  maxPage,
  onListItemClicked,
  popupShow,
  onAddBtnClicked,
  onBackDropClicked,
  onAddDoneClicked,
  setName,
  setPrice,
  setSelectedStore,
  storeOption,
}: Props) => {
  return (
    <div className='content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'상품 관리'}</div>
          <div className='subtitle-label'>{'상품 관리 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          추가하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>ID</div>
          <div className='item'>이름</div>
          <div className='item'>가격</div>
        </div>
        <div className='body-section'>
          {productList.map((item, idx) => (
            <div
              className='row'
              key={idx}
              onClick={() => onListItemClicked(item)}>
              <div className='item'>{item.id}</div>
              <div className='item'>{item.name}</div>
              <div className='item'>{formatNumber(item.price)}</div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPageNationContainer onPageChanged={setPage} maxPage={maxPage} />
        </div>
      </div>
      <CModal visible={popupShow} onBackdropClicked={onBackDropClicked}>
        <div
          className='product-add-container'
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className='table-container'>
            <div className='row'>
              <CSelectContainer
                options={storeOption}
                onSelected={setSelectedStore}
                labelSelector={(item) => item.name}
              />
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={setName}
                type='text'
                inputId='product-name-input'
                label='이름'
              />
              <CInputContainer
                containerType={{
                  type: 'outline',
                  adaptive: false,
                }}
                onInputChanged={(val) => setPrice(parseInt(val))}
                type='number'
                inputId='product-price-input'
                label='상품 가격'
              />

              <button className='primary-btn' onClick={onAddDoneClicked}>
                완료
              </button>
            </div>
          </div>
        </div>
      </CModal>
    </div>
  );
};

export default AdminProduct;
